import { Block } from 'baseui/block';
import { Card } from 'baseui/card';
import React, { useEffect } from 'react';
import { useWindow } from '../../context/use-window';
import theme from '../../page-layouts/theme';
import { Hero } from '../banner/home-page-banner';
import FooterSection from '../footer';
import { GetGuideSection } from '../get-guide-section/get-guide-section';
import { GoSolarNow } from '../go-solar-now/go-solar-now';
import NavBar from '../nav-bar/nav-bar';
import QuotationMobile from '../quotation/quotation-mobile';
import { WhySunnyEnergy } from '../why-sunny-energy/why-sunny-energy';
import { HomepageProps } from './homepage';
import { useQueryParam } from '../../context/use-query-string';
import DiscountOfferPopup from '../discount-offer-popup/discount-offer-popup';
import Reviews from '../projects/reviews';
import AwardsSection from '../reviews-and-awards/reviews-and-awards';

const MobileHomepage: React.FC<HomepageProps> = ({ homepage }) => {
  const scrollTo = useQueryParam('scrollTo');

  const handleClickScroll = () => {
    var element = document.getElementById(scrollTo);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  };

  const { currentWindow } = useWindow();

  useEffect(() => {
    if (currentWindow) {
      handleClickScroll();
    }
  }, [currentWindow]);

  return (
    <>
      <NavBar data={homepage.data.attributes.Header} position={'fixed'} />
      <Block paddingTop={'80px'}>
        <DiscountOfferPopup
          data={homepage.data.attributes.InformativeBanner || []}
        />
      </Block>
      <Hero
        data={homepage.data.attributes.Banners}
        homepage={homepage.data.attributes.InformativeBanner}
      />
      <Block
        id="solar"
        backgroundColor={theme.colors.backgroundPrimary}
        padding="scale600"
        paddingTop="scale1600"
        paddingBottom="scale1600"
        display={['block', 'block', 'block', 'none']}
      >
        <Card
          overrides={{
            Root: {
              style: () => ({
                border: `none`,
                borderRadius: theme.sizing.scale1000,
                paddingTop: theme.sizing.scale400,
                backgroundColor: theme.colors.accent100,
              }),
            },
          }}
        >
          <QuotationMobile data={homepage.data.attributes.Quote} />
        </Card>
      </Block>

      <Block>
        <WhySunnyEnergy data={homepage.data.attributes.LearnMoreSection} />
      </Block>
      <GoSolarNow data={homepage.data.attributes.GoSolarNowSection} />
      {/* Temperory removal of the animation sections - should add once approved. */}
      {/* <Block display="none">
        {homepage.data.attributes.Animations.map((animation, i) => (
          <VideoAnimation
            data={animation}
            key={`${i}`}
            backgroundColor={i % 2 === 0 ? '' : theme.colors.backgroundPrimary}
          />
        ))}
      </Block> */}
      <Reviews data={homepage.data.attributes.Reviews} />
      <AwardsSection
        awards={homepage.data.attributes.Awards}
      />
      <GetGuideSection data={homepage.data.attributes.GetSolarGuideSection} />

      <FooterSection data={homepage.data.attributes.Footer} />
    </>
  );
};

export default MobileHomepage;
